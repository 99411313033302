<template>
<v-app :style="colorVapp">
  <onebox_adminonebox_toolbar
  :quicksearch="true"
  @loadfile="loadfolder()"
  @callstorage="loadstorage()"
  @closeDrag="removeEvent()"
  @openDrag="addEvent()"
></onebox_adminonebox_toolbar>
<v-content>
  <v-card class="elevation-0" >
    <v-divider></v-divider>
      <v-overlay :value="processloader" absolute :color="color.BG" dark>
        <v-progress-circular indeterminate size="64" :color="color.theme"></v-progress-circular>
        <span :style="colorProgress">&nbsp; loading</span>
      </v-overlay>
   <!-- hearder -->
        <v-list nav :color="color.BG">
          <v-list-item class="text-left">
            <v-list-item-avatar class="text-center">
              <v-avatar :color="color.theme" size="35">
                <v-icon dark size="20">mdi-folder-edit</v-icon>
              </v-avatar>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                <span
                  :style="headerPage" 
                >&nbsp;Manage Storage</span>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
   <!-- search -->
    <v-card class="elevation-0" :color="color.BG">
      <v-flex xs6 lg4 offset-lg8 class="text-right" style="margin-right: 20px;" :color="color.BG">      
          <!-- <v-autocomplete
            prepend-inner-icon="work"
            v-model="choosetypeuser"
            :item-text="$t('default') === 'en' ? 'nameen': 'nameth'"
            hide-details
            outlined
            dense
            return-object
            :item-color="color.theme"
          > -->
            <!-- <template :color="color.theme" v-slot:item="{ item }">
              <span v-if="$t('default') === 'en'" style="font-size: 14px; " >{{ item.nameen }}</span>
              <span v-else style="font-size: 14px; " >{{ item.nameth }}</span>
            </template>
            <template :color="color.theme" v-slot:selection="{ item }">
              <span v-if="$t('default') === 'en'" style="font-size: 13px; " >{{ item.nameen }}</span>
              <span v-else style="font-size: 13px; "  >{{ item.nameth }}</span>
            </template> -->

          <!-- </v-autocomplete>   -->
         
          <div class="text-right">
            <v-btn
              class="ma-2"
              color="secondary"
              @click="addcompany()"
            >
              Add Company
            </v-btn>
          </div>
      </v-flex>   
    </v-card> 
  
     <!-- table -->
        <v-card class="elevation-0" :color="color.BG">
          <v-card-text class="pa-0">    
             <v-layout row wrap justify-center>      
               <v-flex lg12 class="ma-6">
                <div>
                  <v-data-table
                 
                    :headers="headers"
                    :items="rootfile"
                    :no-data-text="$t('tablefile.empty')"
                    :single-select="false"
         
                    item-key="file_id"
                    class="elevation-1"
                 
                    :hide-default-footer="true"
                    @page-count="pageCount = $event"
                  >
                    <template v-slot:[`header.file_name`]="{ header }">
                      <span class="pointer"  @click="multiSort=!multiSort "
                      :style="headerTable">{{ $t(header.text) }}</span>
                    </template>
                    
                    <template v-slot:[`header.file_size`]="{ header }">
                      <span class="pointer"  @click="multiSort=!multiSort " 
                      :style="headerTable">{{ $t(header.text) }}</span>
                    </template>
                    <template v-slot:[`header.file_use`]="{ header }">
                      <span class="pointer"  @click="multiSort=!multiSort " 
                      :style="headerTable">{{ $t(header.text) }}</span>
                    </template>
                    <template v-slot:[`header.file_lastdtm`]="{ header }">
                      <span class="pointer"  @click="multiSort=!multiSort " 
                      :style="headerTable">{{ $t(header.text) }}</span>
                    </template>
                    <template v-slot:[`header.file_status`]="{ header }">
                      <span :style="headerTable">{{ $t(header.text) }}</span>
                    </template>

                    <template v-slot:item="props">
                      <tr
                        style="cursor: pointer"
                        @contextmenu="test($event,props.item)"
                        @dblclick="gotodirectory(props.item.file_id,props.item.file_type,props.item.account_sender,props.item) , newpreviewfile(props.item)"
                      > 
                        <td width="33%">{{ props.item.file_name}}</td>
                        <td width="18%">{{ formatdatetime(props.item.file_lastdtm)}}</td>
                        <td width="15%">{{props.item.file_size}}</td>
                        <td width="15%">{{props.item.file_use}}</td>
                        <td width="10%" class="text-center" v-if="props.item.file_status === 'M'"></td>
                        <td width="12%" v-else class="text-right">                      
                          <v-btn class="elevation-0" fab small @click="managestorage()">
                            <v-icon>settings</v-icon>
                          </v-btn>
                        </td>
                      </tr>
                    </template>
                  </v-data-table>
                </div>
                <br />
     
                <!-- <v-layout row wrap justify-center>
                  <v-flex xs11 lg11>
                    <v-pagination
                      v-model="page"
                      :length="pageCount || 0"
                      :color="color.theme"
                      v-if="rootfile.length > 0"
                    ></v-pagination>
                  </v-flex>
                </v-layout>          -->
          <!-- <mainrightclick :show="showMenu" :AreaX="x" :AreaY="y" @close="showMenu = false"></mainrightclick> -->
          <!-- <currentfilerightclick
            :parentfolder="parentfolder"
            :show="showcurrentMenu"
            :AreaX="x"
            :AreaY="y"
            @callupdatestatusfile="setPermissionFileStar"
            @closecurrent="showcurrentMenu = false,loadfolder()"
            :file="currentfile"
          ></currentfilerightclick> -->
            
               </v-flex>
             </v-layout>
            <insertcompany
            :show = "opendialoginsertcompany"
            @closedialog ="opendialoginsertcompany = false"          
            >
            </insertcompany>
            <managestorageonebox
            :show = "opendialogmanagestorageonebox"
            @closedialog ="opendialogmanagestorageonebox= false"   
            >
            </managestorageonebox>
          <managerightclick
    
          
          >
          </managerightclick>
           </v-card-text>
           
      </v-card>
  </v-card>
 
</v-content>
  
</v-app>
</template>
<script>
const insertcompany = () => import("../components/optional/dialog-insertcompany");
const managestorageonebox = () => import("../components/optional/dialog-managestorageonebox");
const managerightclick = () => import("../components/contextmenu/managerightclick");
import Swal from "sweetalert2/dist/sweetalert2.js";
import { mapState, mapGetters } from "vuex";
import InfiniteLoading from "vue-infinite-loading";
import "vue-full-screen-file-drop/dist/vue-full-screen-file-drop.css";
import VueFullScreenFileDrop from "vue-full-screen-file-drop";
import onebox_adminonebox_toolbar from "../components/layout/layout-adminonebox-toolbar";

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000
});
export default {
  
  data: function() {
    return {
    managestorageonebox : false,
    managestoragerightclick :false,
    opendialoginsertcompany:false,
    openmanagerightclick:false,
    opendialogmanagestorageonebox:false,

       headers: [
        {
          text: "tablefile.filename",
          value: "file_name",
          width: "33%",
          align: "left",
          sortable: false
        },
        {
          text: "tablefile.lastmodified",
          value: "file_lastdtm",
          width: "18%",
          align: "left",
          sortable: false
        },
        {
          text: "tablefile.filetotalstorage",
          value: "file_size",
          width: "15%",
          align: "left",
          sortable: false
        },
        {
          text: "tablefile.fileuse",
          value: "file_use",
          width: "15%",
          align: "left",
          sortable: false
        },
        {
          text: "tablefile.option",
          value: "file_status",
          width: "12%",
          align: "left",
          sortable: false
        }
      ],
      rootfile:[{
        file_id:"1",
        file_name:'inet',
        file_size:'100 Mb',
        file_use:'5 mb',
        file_lastdtm:'25630507120012',

      },
      {
        file_id:"2",
        file_name:'one',
        file_size:'100 Mb',
        file_use:'90 mb',
        file_lastdtm:'25630507120012',

      }]
    }},
  components:{
    onebox_adminonebox_toolbar,
    insertcompany,
    managerightclick,
    managestorageonebox
    },
   computed: {
    ...mapState(["username", "authorize", "account_active", "service","color"]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
      "dataStorageUsage",
      "dataStorageMax"
    ]),
    resolutionScreen() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
    // pageCount: {
    //   get() {
    //     let l = this.rootfile.length;
    //     let s = this.size;
    //     return Math.ceil(l / s);
    //   },
    //   set(newName) {
    //     return newName;
    //   }
    // },
    // paginatedData() {
    //   const start = (this.page - 1) * this.size;
    //   const end = start + this.size;
    //   return this.rootfile.slice(start, start + this.size);
    // },
    // showdatafilter() {
    //   // console.log(search);
    //   return this.rootfile.filter(item => {
    //     return (
    //       item.file_name.toLowerCase().indexOf(this.search.toLowerCase()) > -1
    //     );
    //   });
    // },
    headerTable(){
      return 'color:'+this.color.theme+';'+'font-weight: 600; font-size:15px;'
    },headerPage(){
      // console.log(this.color.theme);
      
      return 'color:'+this.color.theme+';'+'font-size: 18px; font-weight: 600;'
    },colorVapp(){
      return 'background:'+this.color.BG+';'
    },colorSort(){
      return 'color:'+this.color.alertText+';'
    },colorProgress(){
      return 'color:'+this.color.theme+';'
    }
    
  },
  methods:{
    // managestoragerightclick() {
    //   // console.log(e);
    //   e.preventDefault();
    //   this.sendParentfolder();
    //   this.showcurrentMenu = false;
    //   this.x = e.clientX;
    //   this.y = e.clientY;
    //   this.showMenu = false;
    //   this.$nextTick(() => {
    //     this.currentfile = filename;
    //     this.showcurrentMenu = true;
    //     if (this.showMenu === true) {
    //       this.showcurrentMenu = false;
    //     } else {
    //       setTimeout(() => {
    //         this.showcurrentMenu = true;
    //       }, 300);
    //     }
    //   });
    // },
    addcompany(){
      this.opendialoginsertcompany = true;
      console.log(this.opendialoginsertcompany);
      
      
    },
      managestorage(){
      this.opendialogmanagestorageonebox = true;
      console.log(this.opendialogmanagestorageonebox);
      
      
    },
      formatdatetime(_datetime) {
       
      if (_datetime === "" || _datetime === "-" || _datetime === undefined) {
        return "-";
      } else {
        let dateyear =
          _datetime.split("")[0] +
          _datetime.split("")[1] +
          _datetime.split("")[2] +
          _datetime.split("")[3];
        let datemonth = _datetime.split("")[4] + _datetime.split("")[5];
        let dateday = _datetime.split("")[6] + _datetime.split("")[7];
        let hour = _datetime.split("")[8] + _datetime.split("")[9];
        let minute = _datetime.split("")[10] + _datetime.split("")[11];
        let second = _datetime.split("")[12] + _datetime.split("")[13];
        
        return (
          dateday +
          "/" +
          datemonth +
          "/" +
          dateyear +
          " " +
          hour +
          ":" +
          minute +
          ":" +
          second
        );
        
        
      }
    },
      //แปลงขนาดไฟล์
    calculatesize(_sizefilebyte) {
      let size;
      if (_sizefilebyte >= 1099511992567 && _sizefilebyte <= 1125899906842624) {
        size = (_sizefilebyte / 1099511992567).toFixed(2) + " TB";
      } else if (_sizefilebyte >= 1073741824 && _sizefilebyte < 1099511992567) {
        size = (_sizefilebyte / 1073741824).toFixed(2) + " GB";
      } else if (_sizefilebyte >= 1048576 && _sizefilebyte < 1073741824) {
        size = (_sizefilebyte / 1048576).toFixed(2) + " MB";
      } else if (_sizefilebyte >= 1024 && _sizefilebyte < 1048576) {
        size = (_sizefilebyte / 1024).toFixed(2) + " KB";
      } else if (_sizefilebyte === "-") {
        size = _sizefilebyte;
      } else {
        size = _sizefilebyte + " B";
      }

      return size;
    },
  }
}
</script>
<style >
#thiscontainer {
  height: calc(101vh - 180px);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  -moz-overflow-scrolling: touch;
  -ms-overflow-scrolling: touch;

}

</style>